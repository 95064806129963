import EcologyPage from './EcologyPage'
import {graphql} from 'gatsby'

export default EcologyPage

export const pageQuery = graphql`
  query EcologyPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "ecology-page"}}) {
      rawMarkdownBody
      frontmatter {
        seo {
          title
          description
        }
      }
    }
  }
`
